<template>
	<v-layout fill-height row>
		<w-flex offset-xs1 xs4 mr-2>
			<slot>
				<w-layout align-center column fill-height justify-center>
					<w-flex v-t="'workspaces.no_workspace_available_title'" display-1 mb-4 shrink />
					<w-flex v-t="'workspaces.no_workspace_available_text'" mb-2 shrink subheading />
				</w-layout>
			</slot>
		</w-flex>
		<w-flex xs6 ml-2>
			<w-layout align-center fill-height>
				<NoWorkspaceAvailableImage height="80%" />
			</w-layout>
		</w-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'NoWorkspaceAvailable',
	components: {
		NoWorkspaceAvailableImage: () => ({
			component: import('@/components/Holding/NoWorkspaceAvailableImage')
		})
	}
}
</script>